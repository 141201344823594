.add-container
    width: 100%
    height: 100%
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    background-color: #ffde59
    padding: .5em
    font-size: 1em
    color: black
    font-weight: bold
    gap: .5em
    border: .6em solid #ffde59
.inputclass
    background: transparent
    width: 7vw
    height: auto
    border: none
    color: black
    font-weight: bold
    text-align: center
    text-transform: uppercase
    border-bottom: 2px solid black
    border-radius: none
    &::placeholder
        color: black
.buttonclass
    min-width: 10em
    background-color: rgba(0,0 ,0 ,.8 )
    color: white
    border: none
    border-radius: 1em
    padding: 1em 1.5em 1em 1.5em
    font-weight: bold
    transition-duration: 1s
    &:hover
        transform: scale(0.9,0.9)
        