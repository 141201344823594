@import '../../global'

.menu-child
    width: 100%
    height: 100%
    padding: 1vh 5vw 5em 5vw
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 1.5em
    @media (max-width: 600px)
        padding: 1vh 2vw 10vh 2vw

.menu-children
    padding: 1em
    background: #141414
    backdrop-filter: blur(5px)
    -webkit-backdrop-filter: blur(5px)
    border-radius: 10px
    border: 1.5px solid #56dcd7
    color: white
    font-size: 1em
    letter-spacing: 0.09em
    font-family: 'roboto', serif
    &.child1
        width: 60vw
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        gap: 1vh
        @media (max-width: 600px)
            width: 85vw
            font-size: 0.75em
        & .subchild
            display: flex
            flex-direction: column
            align-items: center
            text-align: center
            gap: 1vh
            @media (max-width: 600px)
                font-size: 0.8em
            & .subchild2
                display: flex
                flex-direction: row
                width: 15em
                align-items: center
                text-align: center
                @media (max-width: 600px)
                    flex-direction: column
                    width: auto
                    font-size: 1em
    &.child2
        display: flex
        flex-direction: column
        justify-content: flex-start
        align-items: center
        gap: 1em
        width: 60vw
        height: auto
        @media (max-width: 600px)
            width: 90vw
        & .title
            @media (max-width: 600px)
                display: flex
                flex-direction: column
                align-items: center
                gap: .5em
                background-color: transparent

            & .inputboxchild
                color: white
                background-color: #246aed
                text-align: left
                border: none
                font-size: 1em
                padding: 0.5vh 0.5vw 0.5vh 0.5vw
                @media (max-width: 600px)
                text-align: center
                &::placeholder
                    color: white
                    font-family: 'Alice', sans-serif
        & .title2
            padding: 0.5em 0.5em 0.5em 0.5em
            background-color: #246aed
            border-radius: .5em
            text-transform: uppercase
        & .child3
            display: flex
            flex-direction: row
            align-items: center
            & .cpu
                width: 2em
                height: 2em
        & .child4
            display: flex
            flex-direction: row
            align-items: center
            gap: 0.5em
            font-weight: bold
            text-transform: capitalize
            background-color: #ffde59
            color: black
            text-align: center
            padding: 0.5vh 0.5vw 0.5vh 0.5vw
            border-radius: 6px
            @media (max-width: 600px)
                flex-direction: column
                width: 90%
                justify-content: center
            & .subchild4
                display: flex
                flex-direction: row
                align-items: center
                gap: 0.5em
            & button
                background-color: rgba(0,0 ,0 ,.8 )
                color: white
                border: none
                border-radius: 1em
                padding: 1em 1.5em 1em 1.5em
                font-weight: bold
                transition-duration: 1s
                &:hover
                    transform: scale(.9,.9)
        & .child5
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-around
            width: 100%
            gap: 1em
            @media (max-width: 600px)
                flex-direction: column
                gap: 2em
            & .column-center
                display: flex
                flex-direction: column
                justify-content: flex-start
                align-items: center
                text-align: center
                gap: 0.5em
                background: rgba(#246aed, 1)
                backdrop-filter: blur(5px)
                -webkit-backdrop-filter: blur(5px)
                border: 1px solid rgba(#ffffff, 0.388)
                border-radius: 10px
                font-size: 1em
                padding: .8em .3em .5em .3em
                color: white
                font-weight: bold
                @media (max-width: 600px)
                    width: 8em
                    font-size: 1.5em
            & button
                background-color: rgba(0,0 ,0 ,.8 )
                color: white
                border: none
                border-radius: 1em
                padding: 1em 1.5em 1em 1.5em
                font-weight: bold
                transition-duration: 1s
                &:hover
                    transform: scale(.9,.9)

.parent-div
    margin-top: 1em
    width: 95vw
    padding: 1.5em
    background: #141414
    backdrop-filter: blur(5px)
    -webkit-backdrop-filter: blur(5px)
    border-radius: 10px
    border: 2px solid #56dcd7
    color: white
    font-size: 1em
    letter-spacing: 0.09em
    font-family: 'Alice', sans-serif
    text-align: center
    position: relative
    @media (max-width: 600px)
        max-width: 95vw
.title3
    padding: 0.5em 0.5em 0.5em 0.5em
    background-color: #246aed
    border-radius: .5em
    text-transform: uppercase

.child6
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin-top: 2em
    width: 100%
    position: relative
    overflow: auto
    scroll-behavior: smooth
    & table
        position: absolute
        top: 100%
        left: 0
        font-size: 0.8em
        table-layout: fixed
    & th
        padding: .5em .5em .5em .5em

.inputbox
    background: transparent
    width: 5vw
    height: auto
    border: none
    color: black
    font-weight: bold
    text-align: center
    text-transform: uppercase
    border-bottom: 2px solid black
    border-radius: none
    &::placeholder
        color: black
    &-multiple
        background: transparent
        border: 1px solid #56dcd7
        border-radius: 1em
        width: 50em
        height: 10em
        color: white
        letter-spacing: 0.09em
        font-family: 'Alice', sans-serif
        padding: .5em
        &:focus::placeholder
            color: transparent
    @media (max-width: 600px)
        width: 4em
        height: auto
        font-size: 0.95em

.subchild3
    width: 5em
    text-align: right
    @media (max-width: 600px)
        text-align: center
        width: auto
