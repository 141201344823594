@import "../../global"

.header-bar
    padding: 1vh .5em 1vh .5em
    width: 100vw
    height: auto
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    background-color: #141414
    border-bottom: 3px
    gap: .5em
    text-align: center
    z-index: 1
   

.logo
    width: auto
    height: 3.5em
    margin-right:0.8em
    margin-left:0.3em
    @media (max-width: 600px)
        width: 2.2em
        height: auto

.logbutton
    width: 12em
    height: 2.5em
    font-family: 'Alice', sans-serif
    font-size: 1em
    font-weight: bold
    border-radius: 2em
    background-color: #ffde59
    color: black
    text-transform: capitalize
    transition-duration: 1s
    padding: 0 1em 0 1em
    &:hover
        transform: scale(0.9,0.9)
        border: 5px solid white
    @media (max-width: 600px)
        width: 7em
        min-height: 2.5em
        font-size: .9em
        font-weight: bolder

.child
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    gap: .5em
    font-size:1.2em
    margin-right:.5em
    @media (max-width: 600px)
       font-size: 1em
       margin-right:.5em
.row-flex
    @include flex-props-row-center
    
    
   
    
.tag
    font-family: 'Roboto Slab', serif
    font-size: 1.5em
    color: white
    text-align: left
    display: block
    margin-bottom: 8px
    @media (max-width: 600px)
       font-size: 1em
       margin-bottom: 6px

.tagLine
    font-family: 'Roboto', san-serif
    font-size: 1em
    color: #4fcfb0
    text-align: left
    display: block
    @media (max-width: 600px)
        font-size: .5em
       
 