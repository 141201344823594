@import "../../global"
$small: 300px
$medium: 900px

.content-box
  width: 100vw
  height: 100vh
  position: relative
  display: flex
  flex-direction: column
  border-radius: 2vh
  z-index: 1
  justify-content: flex-start
  align-items: center
  padding-left: 2vw
  padding-right: 2vw
  overflow: hidden

  &-nav-button
    position: relative
    z-index: 1
    width: 3vw

  &-title, &-subtitle
    text-align: center
    color: #F9F7F6
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black

  &-title
    font-family: "m42"
    font-weight: 400
    font-size: 1.5vw
    text-transform: uppercase
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black

  &-video
    width: 100vw
    height: 100vh
    position: absolute
    background-position: center
    top: 0
    left: 0
    z-index: -2

  &-subtitle
    margin-top: 1px
    font-family: "m42"
    font-weight: 500
    font-size: 1vw
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black

  &-content
    width: 99vw
    height: 100vh
    padding: 0 5em 10em 5em
    margin-bottom: 2vh
    overflow-y: auto
    scroll-behavior: smooth
    scrollbar-highlight-color: #ffde59 !important

::-webkit-scrollbar
  width: .2em

::-webkit-scrollbar-track
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.068)
  border-radius: 5px

::-webkit-scrollbar-thumb
  border-radius: 5px
  box-shadow: inset 0 0 6px #ffde59


.header
  width: 100vw
  @include flex-props-space-between
  z-index: 1

  .header-log-out
    width: 6vw
    height: 4vh
    position: relative
    background-size: cover
    background-repeat: no-repeat
    color: #fff

  .header-assets
    @include flex-props-center

    .header-asset
      @include flex-props-center
      margin-right: 12px

      &:last-child
        margin-right: 0

      .header-asset-image
        height: 25px
        width: auto
        margin-right: 5px

  .header-info
    @include flex-props-center

    .header-info-item
      @include flex-props-center
      padding: 1vw 1vw 1vw 
      width: auto
      height: auto 
      
      .header-info-item-image
        height: 5vh
        width: auto

      .header-info-item-text
        font-family: "m42"
        font-size: 3vh
        color: #fff

  @media screen and (min-width: $small) and (max-width: $medium)
    .content-box
      