@import "../global"

.portrait-mode
    width: 100vw
    height: 100vh
    @include background-props

.portrait
    width: 100vw
    height: 100vh
    background-color: black
    //background-image: url('../assets/backgrounds/background.png')
    background-position: center
    background-size: contain
    background-repeat: no-repeat
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: white
    font-size: 5vw
    font-weight: bold

.rotategif
    width: 100vw
    height: 50vh
