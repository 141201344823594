.button-parent1s
    display: flex
    flex-direction: row
    justify-content: flex-start
    align-items: center
    gap: 1em

.button-div
    padding: .5em
    border: 2px solid #56dcd7
    background: transparent
    color: white
    font-size: 1em
    font-family: 'Alice', sans-serif
    border-radius: .5em
    transition-duration: 1s
    &:hover
        border: 2px solid white

.bulk
    margin-top: 1em
    border: 0px solid #56dcd7
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    gap: 1em
    padding: .5em
.bulk-child
    width: 100%
    padding: 0.5em 1em 0.5em 1em
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    gap: 1em
    border: 2px solid #56dcd7
    @media (max-width: 600px)
        font-size: 0.5em
.search
    margin-left: .5em
    width: 1.5em
    height: auto
    cursor: pointer
    transition-duration: 1s
    &:hover
        transform: scale(1.1,1.1)
        
.waxFor
    font-size: 1em
    @media (max-width: 600px)
       font-size: 1.5em

.inputclass
    @media (max-width: 600px)
       width: 3.5em   
.buttonclass1
    min-width: 10em
    background-color: rgba(0, 0, 0, .8)
    color: white
    border: none
    border-radius: 1em
    padding: 1em 1.5em 1em 1.5em
    font-weight: bold
    transition-duration: 1s

    &:hover
        transform: scale(0.9, 0.9)

    @media (max-width: 600px)
        padding: .6em 1.5em .6em 1.5em
        min-width: 6em
