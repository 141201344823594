@import "../../global"
.menu-option
    width: 100%
    height: 60%
    display: flex
    flex-direction: column
    justify-content: space-evenly
    align-items: center

.button
    cursor: pointer
    transition-duration: 1s
    &:hover
        transform: scale(0.9,0.9)