.typer{
    color: #fff;
    max-width: 80%;
    max-height: 50vh;
    font-family: monospace;
    overflow: hidden;
    white-space: wrap;
    word-wrap: break-word;
    margin: 0 auto;
    letter-spacing: .10em;
    padding: 1vh 2vw 1vh 2vw;
    font-size: 1.5vw;
    text-align: center;
}