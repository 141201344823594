@import 'reset.sass'
@import 'global.sass'
@import 'fonts.sass'

body
    & iframe
        display: none
    background: #000000
    scroll-behavior: smooth
    
