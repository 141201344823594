$iphone-se: 670px
$ipad-pro: 1370px
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Alice&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Roboto+Slab:wght@500&display=swap')
@mixin background-props
    background: black 
    background-size: 100% 100%
    background-repeat: no-repeat
    background-position: center

@mixin flex-props-center
    display: flex
    align-items: center
    justify-content: center

@mixin flex-props-row-center
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start

@mixin flex-props-column-center
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center

@mixin flex-props-column-space-between
    display: flex
    align-items: center
    flex-direction: column
    justify-content: space-between

@mixin flex-props-row-space-around
    display: flex
    align-items: center
    flex-direction: row
    justify-content: space-around

@mixin flex-props-space-between
    display: flex
    justify-content: space-between
    align-items: center

@mixin flex-props-evenly
    display: flex
    justify-content: space-evenly
    align-items: center

@mixin smallest-phone
    @media (max-width: 350px)
        @content

@mixin small-phone
    @media (max-width: 500px)
        @content

@mixin phone
    @media (max-width: 768px)
        @content

@mixin small-tablet
    @media (min-width: 500px) and (max-width: 768px)
        @content

@mixin tablet
    @media (max-width: 1024px)
        @content

@mixin image-background
    background: #000000
    height: auto
    width: auto
    background-repeat: no-repeat
    background-size: cover
    overflow-x: clip
    overflow-y: clip
    scroll-behavior: smooth
    @include flex-props-center  

@mixin border-background
    position: relative
    border: 2px solid #DA9859
    border-radius: 10px
    background-color: #EBDBC0

    &:before
        content: ""
        position: absolute
        top: -4px
        left: -4px
        bottom: -4px
        right: -4px
        border-radius: 15px
        background-color: #7C5438
        z-index: -1

@mixin title-props
    font-size: 30px
    color: #7C5438
    margin-bottom: 10px
    @media (min-height: 1000px) and (max-height: $ipad-pro)
        font-size: 45px

@mixin subtitle-props
    font-size: 18px
    max-width: 200px
    color: #7C5438
    margin-bottom: 15px
    text-align: center
    line-height: 21px
    @media (min-height: 1000px) and (max-height: $ipad-pro)
        font-size: 25px

@mixin page-props


/* width */
:-webkit-scrollbar 
    width: 10px

/* Track */
:-webkit-scrollbar-track 
    background: #f1f1f1

/* Handle */
:-webkit-scrollbar-thumb 
    background: #888

/* Handle on hover */
::-webkit-scrollbar-thumb:hover     
    background: #555

.inputbox::-webkit-inner-spin-button,
.inputbox::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
    display: none

.inputclass::-webkit-inner-spin-button,
.inputclass::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
    display: none

@mixin smallest-phone
    @media (max-width: 350px)
        @content

@mixin small-phone
    @media (max-width: 500px)
        @content

@mixin phone
    @media (max-width: 768px)
        @content

@mixin small-tablet
    @media (min-width: 500px) and (max-width: 768px)
        @content

@mixin tablet
    @media (max-width: 1024px)
        @content

@mixin desktop
    @media (max-width: 1200px)
        @content
